const appConfig = {
  apiPrefix: "/api",
  verifyPath: "/verify-email",
  authenticatedEntryPath: "/dashboard",
  authenticatedClientEntryPath: "/client/list",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  enableMock: true,
  pagePerData: 10,
};

export default appConfig;
